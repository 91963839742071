import uuid from "uuid";
import { MessageDataType, MsgCategory } from "./types";
import { MsgType } from "./types";

// export function resolveType(data: any){
//   const type = data && data.type ? data.type : null;

//   if(type === MsgType.RECORD_CHANGED)
//     return data as MessageData[MsgType.RECORD_CHANGED] 
//   if(type === MsgType.RECORD_CANCELED)
//     return data as MessageData[MsgType.RECORD_CANCELED] 
//   if(type === MsgType.ORDER_CANCELLED)
//     return data as MessageData[MsgType.ORDER_CANCELLED] 

//   return null;
// }


export function parseMessage(parsed: MessageDataType, userId: number){
  
  parsed.userId = userId;
  parsed.id = uuid();
  
  if(parsed.type === MsgType.RECORD_CHANGED){
    parsed.showOld = false;
    parsed.category = MsgCategory.WARNING;
    return parsed
  }
  if(parsed.type === MsgType.ORDER_CANCELLED){
    parsed.category = MsgCategory.WARNING;
    return parsed
  }
  if(parsed.type === MsgType.RECORD_CANCELED){
    parsed.category = MsgCategory.ALERT;
    return parsed
  }
  if(parsed.type === MsgType.ORDER_CHANGED){
    parsed.category = MsgCategory.WARNING;
    return parsed
  }
  if(parsed.type === MsgType.ORDER_CHANGED_BY_SOS){
    parsed.category = MsgCategory.WARNING;
    parsed.orderId = parsed.recordId ? undefined : parsed.orderId; // Hack to redirect to towing_record_page if towing record exists
    return parsed
  }
}