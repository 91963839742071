
export enum MsgCategory {
  ALERT = 'alert',
  WARNING = 'warning',
  INFO = 'info'
}

export enum MsgType {
  ORDER_CANCELLED = 'orderCancelled',
  RECORD_CANCELED = 'recordCancelled',
  RECORD_CHANGED = 'recordChanged',
  ORDER_CHANGED = 'orderChanged',
  ORDER_CHANGED_BY_SOS = 'orderChangedBySos'
}

export type MessageData = {
  [T in MsgType]: {
    [MsgType.ORDER_CANCELLED]: OrderCancelledMessage,
    [MsgType.RECORD_CANCELED]: RecordCancelledMessage,
    [MsgType.RECORD_CHANGED]: RecordChangedMessage,
    [MsgType.ORDER_CHANGED]: OrderChangedMessage,
    [MsgType.ORDER_CHANGED_BY_SOS]: OrderChangedMessage,
  }[T]
};

export type MessageDataType = OrderCancelledMessage | RecordCancelledMessage | RecordChangedMessage | OrderChangedMessage | OrderChangedBySosMessage

// export type MessageData = OrderCancelledData | RecordCancelledData | RecordChangedData

export interface MessageDataBase {
  type: MsgType
  msg: string
  header: string
  timeStamp: string
  orderId?: number
  recordId?: number
  userId: number
  id: string
}

// export interface IOrderCanceledMessageData<T> extends{
//     header: string
//     msg: string
//     type: MsgType.
//     data: T
//     // changedData?: MessageData
//     // towingDate?: string
//     // towingTime?: string
//     // vehicleMakeAndModel?: string
//     // from?: string
//     // to?: string
//     orderId?: number
//     recordId?: number
//     category?: MsgCategory 
//     showOld?: boolean
//     userId: number
//     id: string
// }

export interface OrderCancelledMessage extends MessageDataBase{
  type: MsgType.ORDER_CANCELLED
  category: MsgCategory.WARNING 
  data: {
    towingDate?: string
    towingTime?: string
    vehicleMakeAndModel?: string
    from?: string
    to?: string  
  }
}

export interface RecordCancelledMessage extends MessageDataBase {
  type: MsgType.RECORD_CANCELED
  category: MsgCategory.ALERT
  data: {
    towingDate?: string
    towingTime?: string
    vehicleMakeAndModel?: string
    from?: string
    to?: string 
  }
}

export interface RecordChangedMessage extends MessageDataBase {
  type: MsgType.RECORD_CHANGED
  category: MsgCategory.WARNING
  showOld: boolean
  data: {
    editedBy: string
    towingTargets?: TowingTargetsType
    values?: ChangedDataType[]
  }
}


export interface OrderChangedMessage extends MessageDataBase {
  type: MsgType.ORDER_CHANGED
  category: MsgCategory.WARNING
}

export interface OrderChangedBySosMessage extends MessageDataBase {
  type: MsgType.ORDER_CHANGED_BY_SOS
  category: MsgCategory.WARNING
  data: {
    changedFields: {
      [key: string]: string
    }
  }
}

interface ChangedDataType {
    attr: string | null
    name: string | null
    new: string | null
    old: string | null
}
interface TowingTargetsType {
    name: string | null
    data: TowingTargetsDataType[]
}
interface TowingTargetsDataType {
    prefix: string | null
    newVal: string | null
    oldVal: string | null
}